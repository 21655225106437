import React, { Component } from "react";
import {
  PtBasicInformation,
  PtCard,
  PtChildTitle,
  PtDrawer,
  PtIcon,
  PtMaintainRecords,
  PtProgress,
  PtTab,
  PtTableLine,
} from "../../components";
import { connect } from "react-redux";
import axios from "axios";
import "./index.scss";
import { PtTimeMonitoring, PipeLineContent } from "../components/components";
import { PumpStationDetails } from "../assetsSkin/components";
import { c3 } from "../../modules/ces2d/Cesium";
import { mercator2towgs84 } from "../../modules/ces2d/tool";
import { typeList } from "./villageData";
import VillageTable from "../components/villageTable";
import { rightTab1, rightTab2 } from "../data";
import { PtRemotely } from "../../components/activeContent";
import CloseButton from "../moduleSkin/components/general/colseButton";
import { Entity, Color, DistanceDisplayCondition } from "cesium";
import VillageLegend from "./components/villageLegend";
import jing from "../../assets/icon/jing.png";
import shi from "../../assets/icon/shi.png";
import hfc from "../../assets/icon/hfc.png";
import jlj from "../../assets/icon/jlj.png";
import { homeActionCreators } from "../../store/home";
import PumpStationMonitoring from "../assetsSkin/components/pumpStation/pumpStationMonitoring";
import { message } from "antd";

interface Props {
  CesMaps: any;
  drawerVisible: boolean;
  headAreaName: string;
  headSuffixKey: string;
  suTangList: any[];
  monitorShow: any;
  control: (index: number) => void;
  setMonitorShow: () => void;
}

interface State {
  activeType: number; //选中设备类型(1:设备,2:管井)
  deviceList: any[]; //设备列表
  activeData: any; //选中的设备
  rightTabKey: number; //右侧展示内容
  openKey: number; //实时监测下当前展示内容(0:报警记录,1:监测指数)
  isOpenDetails: boolean; //设备详情
  activePipeline: any; //选中的管线
  EntityListId: any; //实体列表
  lineRadarId: any; //实体id
  activePolygon: any;
  distinctionType: number; //用于区分泵站和场站(1:场站,2:泵站)
  entity: Entity;
  label: any;
  wellList: any[];
  wellTotal: number; //管井总数
  legendShow: any;
  statisticalData: any[];
}

class VillageSkin extends Component<Props, State> {
  // 切记，用完一定要清空，用自带的destruct方法
  moveLineList: any[];
  // bz: any = []; // 泵站
  // cz: any = []; // 场站
  // zg: any = []; // 主管
  gj: any = []; // 管井
  jhg: any = []; // 接户管
  zg: any = []; // 污水主管
  yjh: any = []; // 已接户
  wjh: any = []; // 未接户
  hfc: any = []; //化粪池
  jlj: any = []; //截流井
  constructor(props) {
    super(props);
    this.moveLineList = [];
    this.state = {
      activeType: 1,
      deviceList: [],
      activeData: {},
      rightTabKey: 0,
      openKey: 1,
      isOpenDetails: false,
      activePipeline: {},
      EntityListId: [],
      lineRadarId: null,
      activePolygon: {},
      distinctionType: 1,
      entity: undefined,
      label: undefined,
      wellList: [],
      wellTotal: 0,
      legendShow: {
        0: true,
        1: true,
        2: true,
        3: true,
        4: true,
        5: true,
        6: true,
        7: true,
      },
      statisticalData: [
        {
          title: "主管",
          value: 4782,
          unit: "m",
        },
        {
          title: "接户管",
          value: 6372,
          unit: "m",
        },
        {
          title: "泵站",
          value: 6,
          unit: "个",
        },
        {
          title: "场站",
          value: 0,
          unit: "个",
        },
        {
          title: "管井",
          value: 0,
          unit: "个",
        },
        {
          title: "已接户",
          value: 598,
          unit: "个",
        },
      ],
    };
  }

  componentDidMount(): void {
    this.getEquipmentList();
    this.addLine();
    this.addPolygon();

    this.addAuxiliary();
    /**
     * 点击事件
     */
    this.onLeftClick();

    this.getStatisticalData();

    


    let { viewer } = this.props.CesMaps;
    viewer.scene.canvas.addEventListener('click', () => {
      const menu = document.getElementById('customMenu')
      menu && document.body.removeChild(menu);
      // menu.style.display = 'none';
    });
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (this.props.headSuffixKey !== prevProps.headSuffixKey) {
      this.setState(
        {
          activeType: 1,
          activeData: {},
          rightTabKey: 0,
          openKey: 1,
          isOpenDetails: false,
        },
        () => {
          /**
           * 每次绘制或者更新时，先清除之前的实体
           */
          this.clearEnity();
          

          this.getEquipmentList();
          this.addLine();
          this.addPolygon();
          this.addAuxiliary();

          this.getStatisticalData();
        }
      );
    }
  }

  /**
   * @description: 左键点击事件，获取实体，更新相关参数
   * @param {*}
   * @return {*}
   */
  onLeftClick = () => {
    let { Events, viewer } = this.props.CesMaps;
    
    /**
     * 创建一个事件对象
     */
    const event = new Events(viewer);
    /**
     * 调用鼠标滚轮滚动事件传入方法，可以实时监听
     */
    event.clickLeft((feature, event) => {
      let { activeData, entity, label, activePipeline } = this.state;
      if (!feature.id) {
        return;
      }
      if (entity) {
        //@ts-ignore
        switch (entity.type) {
        }
      }
      this.setState(
        {
          entity: feature.id,
        },
        () => {
          const position = viewer.scene.pickPosition(event.position);
          if (position) {
          }
        }
      );

      switch (feature.id.type) {
        /**
         * @description: PUTIAN_POLYLINE 流水线选中效果更新，还有对应的UI界面数据更新
         * @param {*}
         * @return {*}
         */
        case "wushui":
          const id = feature.id.id.split("_")[0];
          this.setSelectLine(`${id}_static`, 8);
          this.setSelectLine(`${id}_active`, 8);
          if (activePipeline.lineId) {
            if (activePipeline.lineId.includes("jiehu")) {
              this.setSelectLine(activePipeline.lineId, 2);
            } else {
              if (id !== activePipeline.lineId) {
                this.setSelectLine(`${activePipeline.lineId}_static`, 3);
                this.setSelectLine(`${activePipeline.lineId}_active`, 3);
              }
            }
          }

          this.setState({
            activeData: {},
            activePolygon: {},
            activePipeline: { ...feature.id.data, lineId: id },
          });
          break;
        case "jiehu":
          if (activePipeline.lineId) {
            if (activePipeline.lineId.includes("jiehu")) {
              if (feature.id.id !== activePipeline.lineId)
                this.setSelectLine(activePipeline.lineId, 2);
            } else {
              this.setSelectLine(`${activePipeline.lineId}_static`, 3);
              this.setSelectLine(`${activePipeline.lineId}_active`, 3);
            }
          }

          this.setSelectLine(feature.id.id, 8);
          this.setState({
            activeData: {},
            activePolygon: {},
            activePipeline: { ...feature.id.data, lineId: feature.id.id },
          });
          break;
        /**
         * @description: PUTIAN_BILLBOARD_CZ 管井点击事件
         * @param {*}
         * @return {*}
         */
        case "PUTIAN_BILLBOARD_GJ":
          /**
           * 选中动态图表显示
           */
          this.onClickBz(feature.id.position._value);
          /**
           * 选中页面数据更新
           */

          this.setState({
            rightTabKey: 0,
            activeType: 2,
            activePipeline: {},
            activeData: feature.id.data,
          });

          break;
        /**
         * @description: PUTIAN_BILLBOARD_CZ 管井点击事件
         * @param {*}
         * @return {*}
         */
        case "POINT_BENG":
          /**
           * 选中动态图表显示
           */
          this.onClickBz(feature.id.position._value);
          /**
           * 选中页面数据更新
           */
          if (window["lteeid"]) {
            var thisNode = document.getElementById(window["lteeid"]);
            thisNode && thisNode.parentElement.removeChild(thisNode);
          }
          this.setState({
            rightTabKey: 1,
            activeType: 1,
            activePipeline: {},
            distinctionType: 2,
            activeData:
              feature.id.data.id === activeData.id ? {} : feature.id.data,
          });

          break;

        case "POINT_CHANG":
          // this.onClickBz(feature.id.position._value);

          // this.setState({
          //   rightTabKey: 1,
          //   activeType: 1,
          //   activePipeline: {},
          //   distinctionType: 1,
          //   activeData:
          //     feature.id.data.id === activeData.id ? {} : feature.id.data,
          // });

          this.addAreaPolygon(feature.id.data.code);

          break;

        /**
         * @description: 描述方法的内容
         * @param {*}
         * @return {*}
         */
        case "yijiehu_polygon":
          this.setState({
            activeData: {},
            activePipeline: {},
            activePolygon: feature.id.data,
          });
          break;

        default:
          this.setState({
            activePipeline: {},
            activeData: {},
            activePolygon: {},
          });
          break;
      }
    });
    let isModify = true
    // 右键
    event.clickRight((feature, event) => {
      
      
      const position = event.position
      isModify = false
    
      switch (feature.id.type) {
        case "wushui":

          const menu = document.createElement('div');
          menu.id = 'customMenu';
          menu.style.position = 'absolute';
          menu.style.zIndex = '1000';
          menu.innerHTML = '<button id="modifyDirection" >修改流向</button>';
          menu.addEventListener('contextmenu', (event) => {
            event.preventDefault(); // 阻止菜单上右键菜单的默认行为
          });
          document.body.appendChild(menu);
          
          menu.style.left = `${position.x}px`;
          menu.style.top = `${position.y}px`;
          menu.style.display = 'block';
          

          menu.addEventListener('click', async () => {
            if(isModify) return
            isModify = true
            console.log(feature.id.data);
            document.body.removeChild(menu);
            await this.modifyDirection(feature)
          });
          break;
      }

    })
  };

  
  // 修改流向

  modifyDirection = async (feature: any) => {
    const id = feature.id.data.id
    let res = await axios.get(`/pipeline/queryInfoById/${id}`)
    
    const {data} = res
    const newData = {...data}
    newData["lineStartList"] = data.lineEndList;
    newData["lineEndList"] = data.lineStartList;
    newData["reverse"] = !newData["reverse"];
      
    const line: any = await axios.get(`/pipeline/reverse/${newData["geoLine"].toString()}`)
    if (line.code === 200) {
      newData["geoLine"] = res.data;
      
      const edit: any = await axios.put("/pipeline/editInfo", newData)
      
      if (edit.code === 200) {
        message.success("修改成功");
        
        this.clearEnity();
        this.getEquipmentList();
        this.addLine();
        this.addPolygon();
        this.addAuxiliary();
        this.getStatisticalData();
      }
    }
  }

  /**
   * 普通点位点击事件
   */
  onClickBz = (position) => {
    const { entities, viewer } = this.props.CesMaps;
    let entitie = new entities(viewer);
    entitie.postRender({
      position: position,
      size: 32,
      src: "普通选中",
      event: this.onClickBzToo,
    });
  };
  /**
   * @description: 更新选中的线粗细
   * @param {*}
   * @return {*}
   */
  setSelectLine = (id, width) => {
    const { entities, viewer } = this.props.CesMaps;
    let entitie = new entities(viewer);
    entitie.getById(id).polyline.width = width;
  };
  /**
   * @description: 再次点击普通点时
   * @param {*}
   * @return {*}
   */
  onClickBzToo = () => {
    this.setState({
      activeData: {},
      activePipeline: {},
    });
  };
  /**
   * @description 获取设备列表
   */

  getEquipmentList = () => {
    const {
      headSuffixKey,
      CesMaps: { entities, viewer },
    } = this.props;
    const { EntityListId } = this.state;
    const list = [
      {
        name: "泵站设备",
        dataList: [],
      },
      {
        name: "场站设备",
        dataList: [],
      },
    ];
    const data = {
      areaId: headSuffixKey,
    };
    axios
      .get("/device/query_all_group_by_area", {
        params: { ...data, type: 7 },
      })
      .then((res: any) => {
        if (res.code === 200) {
          list[1].dataList =
            res.data.list.length > 0 ? [...res.data.list[0].list] : [];
        }
      });
    axios
      .get("/device/query_all_group_by_area", {
        params: { ...data, type: 6 },
      })
      .then((res: any) => {
        if (res.code === 200) {
          list[0].dataList =
            res.data.list.length > 0 ? [...res.data.list[0].list] : [];
        }
      });
    setTimeout(() => {
      this.setState({
        deviceList: list,
      });
    }, 200);

    axios.get(`/well/query_by_areaId/${headSuffixKey}`).then((res: any) => {
      if (res.code === 200) {
        let total = 0;
        let newList = [];
        res.data.forEach((item) => {
          total = total + item.dataList.length;
          newList.push(...item.dataList);
        });

        newList.forEach((item) => {
          const { id, geoPoint } = item;
          let entitie_ = new entities(viewer);
          const attr = item.attributeList && item.attributeList.find(attr => attr.pipeAttributeItemId === 41)
          entitie_.options({
            name: item.serial,
            id: id,
            data: item,
            position: geoPoint,
            type: "PUTIAN_BILLBOARD_GJ",
            billboard: {
              url:  attr ? shi :jing,
              Zindex: 1000,
              scale: 0.1,
            },
            distanceDisplayCondition: new DistanceDisplayCondition(0, 6000),
          });
          entitie_.add();
          EntityListId.push(viewer.entities.getById(id));
          this.gj.push(viewer.entities.getById(id));
        });

        this.setState({
          wellList: res.data,
          wellTotal: total,
        });
      }
    });
  };
  /**
   * @description 设备详情
   * @returns
   */

  getDeviceDetails = () => {
    const { activeType, activeData } = this.state;
    switch (activeType) {
      case 1:
        return <div></div>;
      case 2:
        return <PumpStationDetails data={activeData} />;
    }
  };

  getData = (groupType) => {
    const { headSuffixKey } = this.props;
    return axios.post("/dxf_layer/get/equipmentList/areaId", {
      areaIds: [headSuffixKey],
      groupType,
    });
  };
  /**
   * @description 获取对应设备内容
   * @returns
   */

  getEquipmentContent = () => {
    const { activeType, activeData, rightTabKey, openKey, distinctionType } =
      this.state;

    switch (rightTabKey) {
      case 0:
        return (
          <PtBasicInformation
            data={activeData}
            type={activeType === 2 ? 0 : distinctionType}
          />
        );
      case 1:
        return (
          // <PtTimeMonitoring
          //   data={activeData}
          //   type={distinctionType}
          //   openKey={openKey}
          //   setOpenKey={(value) => {
          //     this.setState({ openKey: value });
          //   }}
          //   renewState={() => {
          //     activeData.alarmNum =
          //       activeData.alarmNum !== 0 ? activeData.alarmNum - 1 : 0;
          //   }}
          // />
          <PumpStationMonitoring
              data={activeData}
              renewState={() => {
                activeData.alarmNum =
                  activeData.alarmNum !== 0 ? activeData.alarmNum - 1 : 0;
              }}
              type={distinctionType}
              openKey={openKey}
              setOpenKey={(value) => {                
                this.setState({ openKey: value });
              }}
            />
        );
      case 2:
        return <PtRemotely data={{}} />;
      case 3:
        return <PtMaintainRecords data={{}} />;
    }
  };

  /**
   * @description         添加管线
   */

  addLine = () => {
    const {
      CesMaps: { entities, viewer },
    } = this.props;
    const { EntityListId } = this.state;
    this.getData(2).then((res: any) => {
      if (res.code === 200) {
        res.data.forEach((item) => {
          const { id, geoLine, legend } = item;
          if (legend === 1) {
            let entitie_ = new entities(viewer);
            entitie_.options({
              name: item.serial,
              id: id + "moren",
              data: { ...item },
              type: "moren",
              polyline: {
                flow: 0,
                positions: geoLine,
                speed: 1000,
                flowColor: "#bbbbbb99",
                staticColor: "#ffffffcc",
                lineWidth: 2,
                distanceDisplayCondition: new DistanceDisplayCondition(0, 6000),
              },
            });
            EntityListId.push(viewer.entities.getById(id + "moren"));
            entitie_.add();
          } else if (legend === 2) {
            let entitie_ = new entities(viewer);
            entitie_.options({
              name: item.serial,
              id: id + "zg_active",
              data: item,
              type: "wushui",
              polyline: {
                flow: 1,
                positions: geoLine,
                speed: 1000,
                flowColor: "#bbbbbbbb",
                staticColor: "#0d7eff",
                lineWidth: 3,
                zIndex: 10000,
                distanceDisplayCondition: new DistanceDisplayCondition(0, 6000),
              },
            });
            entitie_.add();
            this.zg.push(viewer.entities.getById(id + "zg_active"));
            EntityListId.push(viewer.entities.getById(id + "zg_active"));
            let entitie__ = new entities(viewer);
            entitie__.options({
              name: item.serial,
              id: id + "zg_static",
              data: { ...item },
              type: "wushui",
              polyline: {
                flow: 0,
                positions: geoLine,
                speed: 1000,
                flowColor: "#bbbbbb99",
                staticColor: "#0d7eff",
                lineWidth: 3,
                zIndex: 1000,
                distanceDisplayCondition: new DistanceDisplayCondition(0, 6000),
              },
            });
            entitie__.add();
            EntityListId.push(viewer.entities.getById(id + "zg_static"));
            this.zg.push(viewer.entities.getById(id + "zg_static"));
          } else if (legend === 3) {
            let entitie_ = new entities(viewer);
            entitie_.options({
              name: item.serial,
              id: id + "jiehu",
              data: { ...item },
              type: "jiehu",
              polyline: {
                flow: 0,
                positions: geoLine,
                speed: 1000,
                flowColor: "#bbbbbb99",
                staticColor: "#04cbc8",
                lineWidth: 2,
                distanceDisplayCondition: new DistanceDisplayCondition(0, 6000),
              },
            });
            entitie_.add();
            EntityListId.push(viewer.entities.getById(id + "jiehu"));
            this.jhg.push(viewer.entities.getById(id + "jiehu"));
          }
        });
      }
    });
  };

  /**
   * @description         添加接户
   */

  addPolygon = () => {
    const {
      CesMaps: { entities, viewer },
    } = this.props;
    const { EntityListId } = this.state;
    this.getData(3).then((res: any) => {
      if (res.code === 200) {
        res.data.forEach((item) => {
          const { geoShape, id } = item;
          const entities_ = new entities(viewer);
          entities_.options({
            id: id + "house_info",
            name: item.serial,
            data: item,
            type: "yijiehu_polygon",
            polygon: {
              positions: geoShape,
              color: "#2D6CBE55",
              outlineColor: Color.fromCssColorString("#8697A8"),
              outlineWidth: 3,
              zIndex: 9999,
              height: 0,
              distanceDisplayCondition: new DistanceDisplayCondition(0, 6000),
            },
          });
          entities_.add();
          EntityListId.push(viewer.entities.getById(id + "house_info"));
          this.yjh.push(viewer.entities.getById(id + "house_info"));
        });
      }
    });
  };

  /**
   * @description         添加截流井和化粪池
   */

  addAuxiliary = () => {
    const {
      CesMaps: { entities, viewer },
    } = this.props;
    const { EntityListId } = this.state;
    this.getData(4).then((res: any) => {
      if (res.code === 200) {
        res.data.forEach((item) => {
          const { id, geoPoint, legend } = item;
          let entitie_ = new entities(viewer);
          entitie_.options({
            name: item.serial,
            id: id + "fuzhu",
            data: item,
            position: geoPoint,
            type: "PUTIAN_FuZhu",
            billboard: {
              url: item.legend === 1 ? hfc : jlj,
              Zindex: 1000,
              scale: 0.1,
            },
          });
          entitie_.add();
          EntityListId.push(viewer.entities.getById(id + "fuzhu"));
          if (legend === 1)
            this.hfc.push(viewer.entities.getById(id + "fuzhu"));
          if (legend === 2)
            this.jlj.push(viewer.entities.getById(id + "fuzhu"));
        });
      }
    });
  };

  /**
   * @description: 清楚所有的实体
   * @param {*}
   * @return {*}
   */
  clearEnity = () => {
    const { EntityListId } = this.state;
    const { viewer } = this.props.CesMaps;
    EntityListId.forEach((item) => {
      viewer.entities.remove(item);
    });
    // this.bz = []; // 泵站
    // this.cz = []; // 场站
    // this.zg = []; // 主管
    this.gj = []; // 管井
    this.jhg = []; // 接户管
    this.zg = []; //污水主管
    this.yjh = []; // 已接户
    this.wjh = []; // 未接户
    this.hfc = []; //化粪池
    this.jlj = []; //截流井
    this.setState({
      EntityListId: [],
    });
  };

  /**
   * @description         添加场站区域
   */

  addAreaPolygon = (code) => {
    const { entities, viewer } = (window as any).CesMaps;
    let entity_obj = viewer.entities.getById("CHANG_AREA");
    viewer.entities.removeById("CHANG_AREA");
    if (!entity_obj || code !== entity_obj.data.code) {
      axios.get(`/device_area/query_by_code/${code}`).then((res: any) => {
        if (res.code === 200) {
          const { geomShape } = res.data;
          const entities_ = new entities(viewer);

          entities_.options({
            id: "CHANG_AREA",
            name: "区域",
            data: res.data,
            type: "CHANGE_",
            polygon: {
              positions: geomShape,
              color: "#2D6CBE55",
              outlineColor: Color.fromCssColorString("#8697A8"),
              outlineWidth: 3,
              zIndex: 9999,
              height: 0,
              distanceDisplayCondition: new DistanceDisplayCondition(0, 6000),
            },
          });
          entities_.add();
        }
      });
    }
  };

  changeLegendShow = (value) => {
    const { legendShow } = this.state;
    const newData: any = { ...legendShow };
    newData[value] = !legendShow[value];
    this.setState({
      legendShow: { ...newData },
    });
  };

  getStatisticalData = async () => {
    const { headSuffixKey } = this.props;
    const { statisticalData } = this.state;
    const newData = [...statisticalData];
    //场站数据
    const stations: any = await axios.get("/device/query_all_group_by_area", {
      params: { areaId: headSuffixKey, type: 7 },
    });

    //泵站数据
    const pumpStation: any = await axios.get(
      "/device/query_all_group_by_area",
      {
        params: { areaId: headSuffixKey, type: 6 },
      }
    );

    //管井数据
    const tubeWells: any = await axios.post(
      "/dxf_layer/get/equipmentList/areaId",
      {
        areaIds: [headSuffixKey],
        groupType: 1,
      }
    );

    //基本数据
    const basic: any = await axios.get(
      `/admin_area/get_town_pipe_count/${headSuffixKey}`
    );

    if (stations.code === 200) {
      newData[3].value = stations.data.count;
    }

    if (pumpStation.code === 200) {
      newData[2].value = pumpStation.data.count;
    }

    if (tubeWells.code === 200) {
      newData[4].value = tubeWells.data.length;
    }

    if (basic.code === 200) {
      newData[0].value = basic.data.sewageSupervisorLength;
      newData[1].value = basic.data.buildingUnitePipeLength;
      newData[5].value = basic.data.boundHouseCount;
    }

    this.setState({ statisticalData: newData });
  };

  legendClick = (i: number) => {
    const { control } = this.props;
    this.changeLegendShow(i);
    switch (i) {
      case 0:
        control(0);
        // this.bz.forEach((item) => {
        //   item.show = !item.isShowing;
        // });
        break;
      case 1:
        control(1);
        // this.cz.forEach((item) => {
        //   item.show = !item.isShowing;
        // });
        break;
      case 2:
        control(2);
        this.zg.forEach((item) => {
          item.show = !item.isShowing;
        });
        break;
      case 3:
        this.jhg.forEach((item) => {
          item.show = !item.isShowing;
        });
        break;
      case 4:
        this.gj.forEach((item) => {
          item.show = !item.isShowing;
        });
        break;
      case 5:
        this.yjh.forEach((item) => {
          item.show = !item.isShowing;
        });
        break;
      case 6:
        this.hfc.forEach((item) => {
          item.show = !item.isShowing;
        });
        break;
      case 7:
        this.jlj.forEach((item) => {
          item.show = !item.isShowing;
        });
        break;
    }
  };

  render() {
    const {
      activeType,
      deviceList,
      activeData,
      rightTabKey,
      activePipeline,
      activePolygon,
      wellList,
      wellTotal,
      legendShow,
      statisticalData,
    } = this.state;
    const {
      drawerVisible,
      headAreaName,
      CesMaps,
      monitorShow,
      setMonitorShow,
    } = this.props;
    const tabList = activeType === 2 ? rightTab1 : rightTab2;
    return (
      <>
        <PtDrawer
          visible={drawerVisible}
          left={
            <PtCard title={"设施分布"} entitle={"Facilities distribution"}>
              <div style={{ marginBottom: "30px" }}>
                <PtProgress text={"管网完整率指数"} percent={100} />
              </div>
              <div>
                <PtChildTitle
                  title={`设施清单 ${activeType === 2 ? `(${wellTotal})` : ""}`}
                  rightContent={
                    <PtTab
                      data={typeList}
                      value={activeType}
                      onChange={(value) => {
                        this.setState({
                          rightTabKey: value !== 2 ? 1 : 0,
                          activeType: value,
                          activeData: {},
                        });
                      }}
                    />
                  }
                />
              </div>
              <VillageTable
                dataSource={activeType !== 2 ? deviceList : wellList}
                activeData={activeData}
                onClick={(value) => {
                  const {
                    longitude,
                    latitude,
                    position,
                    id,
                    geoPoint,
                    correlationId,
                  } = value;
                  if (correlationId) value.id = value.correlationId;
                  this.setState({
                    activeData: value.id === activeData.id ? {} : value,
                    distinctionType:
                      activeType === 1 ? (value.type === 6 ? 2 : 1) : 0,
                  });

                  if (monitorShow.visible) {
                    window["EZOPENDemo"].stop();
                    setMonitorShow();
                  }

                  let newArr = [];
                  if (longitude && latitude) {
                    newArr = mercator2towgs84([longitude, latitude]);
                  }
                  if (position) {
                    if (position.x && position.y)
                      newArr = [position.x, position.y];
                  }
                  if (geoPoint) newArr = [...geoPoint];
                  if (newArr.length > 0)
                    this.props.CesMaps.flyTo([...newArr, 500]);
                  if (id === activeData.id) {
                    CesMaps.viewer.scene.postRender.removeEventListener(
                      window["ads"]
                    );
                    document.getElementById("ulEleID").style.display = "none";
                    return;
                  }
                  this.onClickBz(c3([...newArr]));
                }}
              />
            </PtCard>
          }
          right={
            activeData.id ? (
              <PtCard
                title={
                  activeData.title ||
                  activeData.name ||
                  activeData.serial ||
                  activePipeline.serial ||
                  activePolygon.serial
                }
                suffix={
                  <div className={"right__suffix"}>
                    <PtIcon
                      className={`${activeData.collect ? "icon-shoucang2" : "icon-shoucang1"
                        } `}
                      style={{
                        color: "#FFB412",
                      }}
                      onClick={() => { }}
                    />

                    {activeData.alarmNum && activeData.alarmNum !== 0 ? (
                      <div
                        className={"right__suffix__tag"}
                        style={{
                          cursor: "pointer",
                          background: "#fe4445",
                          boxShadow: "0px 0px 6px 0px rgba(255, 69, 68, 0.7)",
                          color: "#fff",
                        }}
                        onClick={() => {
                          this.setState({
                            openKey: 0,
                            rightTabKey: 1,
                          });
                        }}
                      >
                        <PtIcon
                          className={"icon-yujingicon "}
                          style={{
                            marginRight: "4px",
                            fontSize: "14px",
                          }}
                        />
                        报警记录 ({activeData.alarmNum})
                      </div>
                    ) : null}
                    <div
                      className={`right__suffix__tag ${activeData.state === 1 ? "normal" : "abnormal"
                        }`}
                    >
                      {activeData.state === 1 ? "正常" : (activeData.state === 4 ? "离线" : activeData.state === 0 ? "未安装" : "异常")}
                    </div>
                    <CloseButton
                      style={{ marginLeft: "4px" }}
                      onClick={() => {
                        this.setState({
                          activeData: {},
                        });
                        CesMaps.viewer.scene.postRender.removeEventListener(
                          window["ads"]
                        );
                        document.getElementById("ulEleID").style.display =
                          "none";
                        return;
                      }}
                    />
                  </div>
                }
              >
                <div style={{ marginBottom: "8px" }}>
                  <PtTab
                    data={tabList}
                    value={rightTabKey}
                    onChange={(value) => {
                      this.setState({
                        rightTabKey: value,
                      });
                    }}
                  />
                </div>
                {this.getEquipmentContent()}
              </PtCard>
            ) : activePipeline.id ? (
              <PtCard
                title={activePipeline.serial}
                titleStyle={{
                  fontSize: "20px",
                }}
                suffix={
                  <div className="right_suffix">
                    <CloseButton
                      style={{ marginLeft: "4px" }}
                      onClick={() => {
                        if (activePipeline.lineId.includes("jiehu")) {
                          this.setSelectLine(activePipeline.lineId, 2);
                        } else {
                          this.setSelectLine(
                            `${activePipeline.lineId}_static`,
                            3
                          );
                          this.setSelectLine(
                            `${activePipeline.lineId}_active`,
                            3
                          );
                        }
                        this.setState({
                          activePipeline: {},
                        });
                      }}
                    />
                  </div>
                }
              >
                <PipeLineContent data={activePipeline} />
              </PtCard>
            ) : activePolygon.id ? (
              <PtCard
                title={activePolygon.serial}
                titleStyle={
                  activePolygon.serial.length <= 7
                    ? {}
                    : {
                      fontSize: `${activePolygon.serial.length > 10 ? "14px" : "22px"
                        }`,
                    }
                }
                suffix={
                  <div className="right_suffix">
                    <CloseButton
                      style={{ marginLeft: "4px" }}
                      onClick={() => {
                        this.setState({
                          activePolygon: {},
                        });
                      }}
                    />
                  </div>
                }
              >
                <PtTableLine
                  leftText="占地面积(㎡)"
                  rightText={activePolygon.area.toFixed(2)}
                />
              </PtCard>
            ) : null
          }
        />
        <VillageLegend
          headAreaName={headAreaName}
          legendShow={legendShow}
          statisticalData={statisticalData}
          onClick={this.legendClick}
        />


        <div className="map_menu">
          

          
        </div>
      </>
    );
  }
}

const mapState = (state) => ({
  drawerVisible: state.getIn(["home", "drawerVisible"]),
  headSuffixKey: state.getIn(["header", "suffixKey"]),
  headAreaName: state.getIn(["header", "areaName"]),
  suTangList: state.getIn(["home", "suTangList"]),
  monitorShow: state.getIn(["home", "monitorShow"]),
});

const mapDispatch = (dispatch) => ({
  setMonitorShow() {
    dispatch(
      homeActionCreators.setMonitorShow({
        visible: false,
        activeId: null,
      })
    );
  },
});

export default connect(mapState, mapDispatch)(VillageSkin);
